import api from './index'
import authHeader from './auth-header'

class serviceAdmin {

    getAll() {
        return api.get('admin/user/all', { headers: authHeader() })
    }

    getRoleAll() {
        return api.get('admin/role/all', { headers: authHeader() })
    }

    insert(formData) {
        return api.post('admin/user/create', formData, { headers: authHeader() })
    }

    update(formData) {
        return api.put('admin/user/update', formData, { headers: authHeader() })
    }
}

export default new serviceAdmin()