<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon> <span class="primary--text">{{$t('caption.userManagement')}}</span>
      <v-spacer></v-spacer>
      <v-btn 
        class="primary" 
        small
        @click.stop="dialog = true"
      >
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> New User
      </v-btn>
    </v-card-title>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-icon left>{{icons.mdiAccount}}</v-icon> {{ dialogTitle }}
        </v-card-title>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Username"
                  :append-icon="icons.mdiAccount"
                  dense
                  :rules="fieldRules"                  
                  v-model="user.username"
                  required
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Email"
                  :append-icon="icons.mdiEmail"
                  v-model="user.email"
                  dense
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  :items="roleItem"
                  item-text="{}"
                  label="Select role"
                  v-model="user.roles"
                  outlined multiple dense hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip>{{data.item.name}}</v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-checkbox
                      v-model="user.roles"
                      :value="data.item"
                    ></v-checkbox>
                      {{data.item.name}}
                  </template>
                </v-select>
              </v-col>
                          
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="user.court"
                  :items="courts"
                  item-text="nameKh"
                  return-object
                  label="ជ្រើសរើសស្ថាប័នតុលាការ"                                              
                  hide-details outlined dense
                >
                  <template slot="selection" slot-scope="data">
                    <span v-if="$t('lang') == 'KH'">
                      {{data.item.nameKh}}
                    </span>
                    <span v-else>{{data.item.nameEn}}</span>
                  </template>
                  <template slot="item" slot-scope="data">
                    <span v-if="$t('lang') == 'KH'">
                      {{data.item.nameKh}}
                    </span>
                    <span v-else>{{data.item.nameEn}}</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  label="Password"
                  :append-icon="showSecret ? icons.mdiEye : icons.mdiEyeOff"
                  :type="showSecret ? 'text' : 'password'"
                  @click:append="showSecret = !showSecret"
                  dense
                  v-model="user.password"
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-select
                  :items="status"
                  v-model="user.isActive"
                  label="Status"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="save"
          >
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> Save
          </v-btn>
          <v-btn
            class="secondary"
            @click="dialog = false"
          >
            <v-icon left>{{icons.mdiCancel}}</v-icon> Cancel
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
        >
          <template v-slot:[`item.roles`]="{ item }">
            <v-chip 
                color="success" 
                text-color="white" 
                small
                class="font-weight-medium ma-1"
                v-for="role in item.roles" v-bind:key="role.id"
              >
                {{ role.name }}
            </v-chip>        
          </td>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <span v-if="item.isActive === true">
              <v-chip 
                color="success" 
                text-color="white" 
                small
                class="font-weight-medium"
              >
                Active
              </v-chip>
            </span>
            <span v-else>
              <v-chip 
                color="danger" 
                text-color="white" 
                small
                class="font-weight-medium"
              >
                Inactive
              </v-chip>
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-2" @click="editItem(item)">
              {{icons.mdiPencil}}
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              {{icons.mdiDelete}}
            </v-icon>
          </template>
        </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiAccountMultiplePlus,
  mdiPlusThick,
  mdiContentSave,
  mdiCancel,
  mdiAccount,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
} from '@mdi/js';
import userService from '@/services/service.admin';
import SystemService from '@/services/service.system';

export default {
  name: 'user-management',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiAccountMultiplePlus,
        mdiPlusThick,
        mdiContentSave,
        mdiCancel,
        mdiAccount,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'USERNAME', value: 'username' },
        { text: 'EMAIL ', value: 'email' },
        { text: 'ROLE', value: 'roles' },
        { text: 'STATUS', value: 'isActive' },
        { text: 'ACTION', value: 'actions', sortable: false },
      ],
      users: [],
      user: { roles: [], isActive: true },
      dialog: false,
      dialogDelete: false,
      showSecret: false,
      roleItem: [{ id: 1, name: 'ROLE_SUPER' }, { id: 2, name: 'ROLE_STAFF' }],
      status: [{ text: 'Active', value: true }, { text: 'Inactive', value: false }],
      courts: [],
      resultMessage: '',
      editedIndex: -1,
      fieldRules: [v => !!v || 'This field is required', v => /^(?!\s*$).+/.test(v) || 'This field cannot be blank'],
      emailRules: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    };
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'New User' : 'Edit User';
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    userService.getAll().then(response => {
      this.users = response.data;
    });

    userService.getRoleAll().then(response => {
      this.roleItem = response.data;
    });

    SystemService.getCourtAll().then(response => {
      this.courts = response.data;
    });
  },
  methods: {
    itemProps(item) {
      return {
        title: item.nameKh,
        subtitle: item.province,
      };
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.user = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      /*
      alert(JSON.stringify(item))
      this.editedIndex = this.users.indexOf(item);
      this.user = Object.assign({}, item);
      this.dialogDelete = true;
       ----*/
      this.$confirm({
        message: 'Do you want to delete this ' + item.username + ' user?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            userService.remove(item.id).then(
              () => {
                const index = this.users.indexOf(item);
                this.users.splice(index, 1);
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'User has been deleted',
                });
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response.data,
                });
              },
            );
          }
        },
      });
    },
    save() {
      if (!this.$refs.form.validate()) return;

      if (this.editedIndex == -1) {
        userService.insert(this.user).then(
          response => {
            this.user = response.data;
            if (this.user) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: 'User has been created',
              });
              this.dialog = false;
              this.users.push(this.user);
            }
          },
          error => {
            this.resultMessage = error.response.data;
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'error',
              text: this.resultMessage,
            });
          },
        );
      } else {
        alert('update');
        userService.update(this.user).then(
          response => {
            this.resultMessage = response.data;
            Object.assign(this.users[this.editedIndex], this.user);
            if (this.resultMessage) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: 'User has been successfully updated',
              });
              this.dialog = false;
            }
          },
          error => {
            this.resultMessage = error.response.data;
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'error',
              text: this.resultMessage,
            });
          },
        );
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.user = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.user = { roles: [], isActive: true };
      });
    },
  },
};
</script>
